<template>
  <div :class="$style.page">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Фото бренда" prop="images">
        <Uploader
          :limit="1"
          :files="form.images"
          @upload="uploadImages"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Лого" prop="logo">
        <Uploader
          :limit="1"
          :files="form.logo && form.logo.original ? [form.logo] : []"
          @upload="uploadLogo"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" @input="handleNameInput"></el-input>
      </el-form-item>
      <el-form-item label="Отображать на сайте">
        <el-checkbox v-model="form.isActive"></el-checkbox>
      </el-form-item>
      <el-form-item label="Название ссылки" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number v-model="form.orderField" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Перейти на сайт бренда(ссылка)">
        <el-input v-model="form.externalLink"></el-input>
      </el-form-item>
      <el-form-item label="Описание" prop="description" />
      <TextEditor :value.sync="form.description" />
      <SeoBlock
        style="margin-top: 2rem"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
      <el-button type="primary" @click="submitForm('form')">
        Создать
      </el-button>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import SeoBlock from '@/components/moleculs/SEO.vue'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'

import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'
import { transliterate } from '@/helpers/slug'

export default {
  components: {
    TextEditor,
    SeoBlock,
    Uploader,
  },
  ADDWINE_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      form: {
        isActive: false,
        images: [],
        logo: {
          caption: '',
          isActive: true,
          order_field: 0,
          original: '',
        },
        name: '',
        slug: '',
        description: '',
        externalLink: '',
        orderField: 0,
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        images: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        logo: [
          {
            type: 'object',
            required: true,
            trigger: 'change',
            validator: this.validateLogo,
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  methods: {
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    uploadImages(images) {
      this.form.images = images
    },
    uploadLogo(images) {
      this.form.logo = images[0]
    },
    validateLogo(_, value, callback) {
      value && value.original
        ? callback()
        : callback(new Error('Пожалуйста, введите лого'))
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })
          const result = await delivery.ProductsCore.BrandsActions.create(
            this.form,
          )

          loading.close()

          if (result.error) return

          this.showNotification('Бренд успешно создан', 'success')

          this.$router.push(ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.LIST)
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .form {
    & > div > label {
      text-align: left;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
}
</style>
